.loadable-button {
  position: relative;
  overflow: hidden;
  font-family: inherit;

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .text {
      padding-left: 4px;
    }

    &.pending {
      background: inherit;
      filter: brightness(150%) saturate(50%);
    }
    &.success {
      z-index: 0;
      top: 1px;
      left: 1px;
      overflow: hidden;

      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: inherit;
      color: var(--primary);
      white-space: nowrap;

      &::before {
        position: absolute;
        z-index: -1;
        left: 50%;
        width: 0;
        height: 100%;
        animation: expand ease-out 0.5s forwards;
        background: var(--container);
        content: "";
        @keyframes expand {
          to {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
