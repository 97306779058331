$header-h: 72px;

.top-bar {
  display: flex;
  width: 100%;
  height: $header-h;
  min-height: $header-h;
  align-items: center;

  .spacer {
    flex: 1;

    &.right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .logo {
    margin: 0 auto;
  }

  .logout {
    margin-right: 5vw;
    color: var(--primary);
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
  }

  &.fixed {
    position: fixed;
    z-index: 100;
    top: 0;
    background: var(--white);
  }
}
