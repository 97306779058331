.check-animation {
  width: 1em;
  height: 1em;
  path {
    animation: check-path-animation ease-out 0.3s;
    stroke-linecap: round;

    stroke-width: 4px;
  }
}

@keyframes check-path-animation {
  from {
    stroke-dasharray: 0 50;
  }
  to {
    stroke-dasharray: 50;
  }
}
