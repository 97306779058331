.google-sso-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}
