.form-component-container {
  position: relative;
  & > .summary {
    position: absolute;
    overflow: hidden;

    width: calc(100% - 20px);
    margin: 10px 10px 0;

    pointer-events: none;
    text-overflow: ellipsis;

    white-space: nowrap;
  }

  & > form.onboarding-form {
    display: block;
    overflow: hidden;
    height: 30px;
    box-sizing: border-box;

    color: var(--strong);

    opacity: 0;

    :global(.info-tooltip) {
      margin: 10px;
    }
    & > .padding {
      > .error-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px 10px;
      }
      > header.title {
        margin-bottom: 20px;
        font-size: 30px;

        font-weight: 500;
        text-align: center;
      }
    }
    .fields {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      .form-input {
        width: calc(100% - 20px);
      }
    }
    .actions {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-evenly;
      .form-submit,
      .previous {
        border: none;
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;

        transition-duration: 0.3s;
        &:hover,
        &:focus {
          transform: scale(1.05);
        }
      }
      .previous {
        background: none;
        color: var(--inverse);
      }
      .form-submit {
        display: flex;
        height: calc(1em + 20px);
        align-items: center;
        padding: 0 30px;

        border-radius: 50px;

        color: white;
        outline: none;
        &:focus {
          opacity: 0.8;
        }
      }
    }
  }
}
